<template>
  <div class="girlbox topmargin">
    <topNav title="女頻"></topNav>
    <!-- 輪播 -->
    <mt-swipe :auto="3000">
      <mt-swipe-item v-for="item in homeSwiper.content" :key="item.id">
        <router-link :to="'/book/' + item.bookId" v-if="item.book">
          <img :src="item.imgUrl" alt="" v-if="item.book" />
        </router-link>
        <a :href="item.url" v-else>
          <img :src="item.imgUrl" alt="" />
        </a>
      </mt-swipe-item>
    </mt-swipe>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="bianShelf.content"
      :title="bianShelf.name"
      :shelfid="bianShelf.id"
    ></eightShelf>
    <!-- 標籤 -->
    <!-- 熱門標籤 -->
    <!-- <div class="ShelfRecommend normalBox">
      <h3 class="clearfix">
        <div class="shelfName fl clearfix">
          <span class="left-line fl"></span>
          <p class="fl">{{ Atags.name }}</p>
        </div>
      </h3>
      <div class="bscroll" ref="slider">
        <ul
          class="item content clearfix"
          :style="'width:' + (137 / 75) * tags.length + 'rem'"
        >
          <li v-for="(item, index) in tags" :key="index" class="fl">
            <img src="~img/tag@2x.png" alt="" />
            <span :style="randomRgb()">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 廣告位 -->
    <router-link  v-for="i in guanggaoShelf.content" :to="'/book/' + i.bookId">
      <img
              :src="i.imgUrl"
              alt=""
              class="imgRecommend"
      />
    </router-link>
    <!-- 推薦位3 -->
    <threeShelf
      :shelf="hotShelf.content"
      :title="hotShelf.name"
      :shelfid="hotShelf.id"
    ></threeShelf>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="dashenShelf.content"
      :title="dashenShelf.name"
      :shelfid="dashenShelf.id"
    ></eightShelf>
  </div>
</template>

<script>
import { getBookList, homeshelf } from "apiurl/book";
/* 引入better-scroll */
import BScroll from "better-scroll";
export default {
  data() {
    return {
      shelfBookList: [],
      shelf1: [],
      shelf2: [],
      shelf3: [],
      tags: [],
      allShelfList: [],
      homeSwiper: {},
      dashenShelf: {},
      pangShelf: {},
      bianShelf: {},
      guanggaoShelf: [],
      Atags: {},
      reShelf: {},
      pangone: [],
      pangtwo: [],
      hotShelf: {}
    };
  },
  created() {
    this.$nextTick(() => {
      this.getList();
      this.getIdShelf("1201697596624736257");
      /* this.scroll = new BScroll(this.$refs["slider"], {
        startX: 0,
        click: true,
        scrollX: true,
        // 忽略豎直方向的滾動
        scrollY: false,
        eventPassthrough: "vertical"
      }); */
    });
  },
  methods: {
    async getList() {
      let res = await getBookList({ pageNo: 8, pageSize: 59 });
      if (res.success) {
        this.shelfBookList = res.data.items;
        this.shelfBookList.forEach(item => {
          item.introduce = item.introduce.substring(0, 30) + "...";
        });
        this.shelf1 = this.shelfBookList.splice(0, 8);
        this.shelf2 = this.shelfBookList.splice(0, 3);
        this.shelf3 = this.shelfBookList.splice(0, 4);
      }
    },
    getIdShelf(pageId) {
      homeshelf({ pageId }).then(res => {
        if (res.success) {
          this.allShelfList = res.data.shelves;
          this.allShelfList.forEach(item => {
            if (item.id == "yuanshi_Wap_Swipers") {
              this.homeSwiper = item;
            } else if (item.id == "yuanshi_femal_editor_recommend") {
              this.hotShelf = item;
              this.hotShelf.content.forEach(item => {
                item.book.introduce =
                  item.book.introduce.substring(0, 30) + "...";
              });
            } else if (item.id == "yaunshi_female_people_like") {
              this.dashenShelf = item;
            } else if (item.id == "yuanshi_female_popular") {
              this.bianShelf = item;
            } else if (item.id == "yuanshi_female_biaoqian") {
              this.Atags = item;
              this.tags = item.content;
            } else if (item.id == "yuanshi_wap_banner") {
              this.guanggaoShelf = item
            }
          });
        }
      });
    },
    // 隨即色
    randomRgb() {
      let R = Math.floor(Math.random() * 255);
      let G = Math.floor(Math.random() * 255);
      let B = Math.floor(Math.random() * 255);
      return {
        color: "rgb(" + R + "," + G + "," + B + ")"
      };
    }
  }
};
</script>

<style lang="stylus" scoped>
/* 標籤 */
.bscroll
    width 100%
    overflow hidden
    margin-bottom 40px
    ul
        li
            width 104px
            height 59px
            position relative
            margin-right 33px
            line-height 59px
            height 59px
            img
                position absolute
                width 104px
                height 59px
                top 0
                left 0
                z-index -1
            span
                display inline-block
                margin-left 36px
                font-size:24px;
                font-weight 500
</style>
